:root {
  --main-color: #000;
  --fade-color: #666;
  --line-color: #eee;
  --highlight: #aaa;
}

html {
  color: #222;
  font-size: 18px;
  line-height: 1.4;
}

body {
  background-color: #fff;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif;
  font-size: 1rem;
  color: var(--main-color);
  line-height: 1.6rem;
  padding: 8rem;
  padding-top: 4rem;
}

#wrapper {
  max-width: 800px;
}

img {
  width: 100%;
  height: auto;
  padding: 1rem 0;
}

p {
  margin: 1rem 0;
}

/* general stuffs */

header.site {
  margin-bottom: 4rem;
}

a {
  color: var(--main-color);
}

a:hover {
  color: var(--highlight);
}

h1 {
  font-size: 2rem;
  margin-bottom: 0.15rem;
}

h1,
h2,
h3 {
  border-bottom: 1px solid var(--line-color);
}

hr {
  border-top: 1px solid var(--line-color);
}

.left {
  float: left;
}

.right {
  float: right;
}

/* homepage stuffs */

header.site h1 {
  border-bottom: 0;
  font-size: 1.2rem;
}

ul.listing {
  font-size: 1rem;
  list-style: none;
  padding: 0;
}

ul.listing>li {
  margin-bottom: 1rem;
}

div.date {
  font-size: 0.8rem;
  color: var(--fade-color);
  line-height: 1rem;
}

div.date>a {
  color: var(--fade-color);
  text-decoration: none;
}

/* post stuffs */

h1.post-title>a {
  text-decoration: none;
}

div.meta {
  font-size: 0.8rem;
  line-height: 1rem;
}

footer {
  margin-top: 4rem;
}

figure {
  margin: 1rem 0;
}

figcaption {
  margin-top: -1rem;
  font-size: 0.9rem;
}

/* responsive bits */

@media only screen and (max-width: 1000px) {
  body {
    padding: 4rem;
    padding-top: 4rem;
  }

  footer {
    margin-top: 2rem;
  }
}

@media only screen and (max-width: 600px) {
  html {
    font-size: 16px;
  }

  body {
    padding: 2rem;
    padding-top: 2rem;
  }

  header.site {
    margin-bottom: 2rem;
  }

  footer {
    display: none;
  }
}
